@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800&display=swap');

/* Root varaibales */
:root {
    --color-primary: #7380ec;
    --color-danger: #ff7782;
    --color-success: #41f1b6;
    --color-warning: #ffbb55;
    --color-white: #fff;
    --color-info-dark: #7d8da1;
    --color-info-light: #dce1eb;
    --color-dark: #363949;
    --color-light: rgba(132, 139, 200, 0.18);
    --color-primary-varient: #111e88;
    --color-dark-variant: #677483;
    --color-background: #f6f6f9;

    --card-border-radius: 2rem;
    --border-radius-1: 0.4rem;
    --border-radius-2: 0.8rem;
    --border-radius-3: 1.2rem;

    --card-padding: 1.8rem;
    --padding-1: 1.2rem;

    --box-shadow: 4px 4px 8px #cccccf,
        -4px -4px 8px #ffffff;
    --box-shadow-down: inset 3px 3px 6px #cccccf,
        inset -3px -3px 6px #ffffff;
}

* {
    margin: 0;
    padding: 0;
    outline: 0;
    appearance: 0;
    text-decoration: none;
    list-style: none;
    box-sizing: border-box;
}

html {
    font-size: 14px;
}

body {
    width: 100vw;
    height: 100vh;
    font-family: 'Poppins', sans-serif;
    font-size: 0.88rem;
    background: var(--color-background);
    user-select: none;
    overflow-x: hidden;
    color: gray;
}

a {
    color: var(--color-dark);
}

img {
    display: block;
    width: 100%;
}

h1 {
    font-weight: 800;
    font-size: 1.4rem;
}

h2 {
    font-size: 1.2rem;
}

h3 {
    font-size: 0.87rem;

}

h4 {
    font-size: 0.8rem;
}

h5 {
    font-size: 0.77rem;
}

small {
    font-size: 0.75rem;
}

.pre-loader {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
}

.profile-photo {
    width: 2.8rem;
    height: 2.8rem;
    border-radius: 50%;
    overflow: hidden;
}

.text-muted {
    color: var(--color-info-dark);
}

p {
    color: var(--color-dark-variant);
}

b {
    color: var(--color-dark);
}

.primary {
    color: var(--color-primary) !important;
}

.danger {
    color: var(--color-danger) !important;
}

.warning {
    color: var(--color-warning) !important;
}

.success {
    color: var(--color-success) !important;
}

.container {
    display: grid;
    width: 96%;
    margin: 0 auto;
    gap: 1.8rem;
    grid-template-columns: 14rem auto 23rem;
}

aside {
    height: 100vh;
}

aside .top {
    background: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 1.4rem;
}

aside .logo {
    display: flex;
    gap: 0.8rem;
}

aside .logo img {
    width: 2rem;
    height: 2rem;
}

aside .close {
    height: 33px;
    width: 33px;
    padding: 5px;
    border-radius: 30px;
    box-shadow: var(--box-shadow);
    border: 2px solid var(--color-danger);
    display: none;
}

/* ========== SideBar ============ */

aside .sidebar {
    background: var(--color-white);
    display: flex;
    flex-direction: column;
    height: 86vh;
    position: relative;
    top: 3rem;
    overflow: hidden;
}

aside h3 {
    font-weight: 500;
    color: var(--color-dark);
}

aside .sidebar a {
    display: flex;
    color: var(--color-info-dark);
    margin-left: 2rem;
    gap: 1rem;
    align-items: center;
    position: relative;
    height: 4rem;
    transition: all 0.3s ease;
}

aside .sidebar a img {
    height: 21px;
    width: 21px;
    transition: all 0.3s ease;
}

aside .sidebar a:last-child {
    position: absolute;
    bottom: 2rem;
    width: 100%;
}

aside .sidebar a.active {
    background: var(--color-light);
    color: var(--color-primary);
    margin-left: 0;
}

aside .sidebar a.active h3 {
    color: var(--color-primary);
}

aside .sidebar a.active::before {
    content: '';
    width: 6px;
    height: 100%;
    background: var(--color-primary);
}

aside .sidebar a.active i {
    color: var(--color-primary);
    margin-left: calc(1rem - 3px);
}

aside .sidebar a:hover {
    color: var(--color-primary);
    border-radius: 10px 0 0 10px;
    padding-left: 2rem;
    box-shadow: var(--box-shadow-down);
}

aside .sidebar a:hover h3 {
    color: var(--color-primary);
}

/* Main Section */

main {
    margin-top: 1.2rem;
}

main .date {
    display: inline-block;
    background: var(--color-light);
    border-radius: var(--border-radius-1);
    margin-top: 1rem;
    padding: 0.5rem 1.6rem;
}

main .date input[type='date'] {
    background: transparent;
    color: var(--color-dark);
    border: none;
}

main .insights {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1.6rem;
}

main .insights>div {
    background: var(--color-white);
    padding: var(--card-padding);
    border-radius: var(--card-border-radius);
    margin-top: 1rem;
    box-shadow: var(--box-shadow);
    transition: all 0.5s ease;
}

main .insights>div:hover {
    cursor: pointer;
    box-shadow: var(--box-shadow-down);
}

main .insights>div .middle>img {
    padding: 0.5rem;
    border-radius: 50%;
    height: 50px;
    width: 50px;
    font-size: 1.2rem;
}

main .insights>div .middle {
    display: flex;
    gap: 0.2rem;
    align-items: center;
    justify-content: space-between;
}

main .insights h3,
h1 {
    margin: 0.3rem 0 0.6rem;
    font-size: 1.5rem;
    color: var(--color-dark);
    transition: all 0.5s ease;
}

main .insights>div:hover .left {
    transform: scale(1.09);
}

main .insights>div:hover h1 {
    color: var(--color-primary);
}

main .recent-project {
    margin-top: 2rem;
}

main .recent-project h2 {
    margin-bottom: 0.8rem;
}

main .recent-project table {
    background: var(--color-white);
    width: 100%;
    border-radius: var(--card-border-radius);
    padding: var(--card-padding);
    text-align: center;
    box-shadow: var(--box-shadow);
    transition: all 0.3s ease;
}
main .recent-project table:hover {
    box-shadow: var(--box-shadow-down);
}
main .recent-project table thead th {
    color: var(--color-dark);
}
main table tbody td {
    height: 2.8rem;
    font-size: 0.9rem;
    font-weight: 500;
    border-bottom: 1px solid var(--color-light);
    color: var(--color-dark-variant);
}
main .recent-project table tbody tr {
    margin: 20px 0;
    cursor: pointer;
    overflow: hidden;
    transition: all 0.5s ease;
}
main .recent-project table tbody tr:hover {
    border-radius: 10px;
    overflow: hidden;
    transform: scale(1.06);
    box-shadow: var(--box-shadow);
}
main .recent-project table tbody tr:last-child td {
    border: none;
}
td{
    height: 50px;
}
td span{
    border-radius: 10px;
    padding: 5px;
    margin: 5px !important;
    background: var(--color-white);
    box-shadow: var(--box-shadow);
}

main .recent-project a {
    background: var(--color-white);
    text-align: center;
    width: 150px;
    font-size: 1rem;
    font-weight: 500;
    display: block;
    margin: 1.5rem auto;
    color: var(--color-primary);
    padding: 10px;
    border-radius: var(--card-border-radius);
    box-shadow: var(--box-shadow);
    transition: all 0.3s ease;
}

main .recent-project a:hover {
    box-shadow: var(--box-shadow-down);
}

/* ======= Right ============ */

.right {
    margin-top: 1.4rem;
}

.right .top {
    display: flex;
    justify-content: space-around;
    gap: 2rem;
}

.right .top button {
    display: none;
}

.right .theme-toggle {
    background: var(--color-light);
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 1.6rem;
    width: 4.2rem;
    cursor: pointer;
    border-radius: var(--border-radius-1);
}

.right .theme-toggle i {
    font-size: 1.2rem;
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.right .theme-toggle .active {
    background: var(--color-primary);
    color: white;
    border-radius: var(--border-radius-1);
}

.right .profile {
    display: flex;
    gap: 2rem;
    width: 100%;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer;
}

.btn-add img{
    height: 23px;
    width: 23px;
    margin-right: 1rem;
}
.btn-add {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 3rem;
    cursor: pointer;
    height: 4rem;
    border-radius: 2rem;
    border: none;
    font-size: 1.3rem;
    font-weight: 500;
    background: var(--color-white);
    color: var(--color-primary-varient);
    box-shadow: var(--box-shadow);
    transition: all 0.3s ease;
}
.btn-add:hover {
    color: var(--color-primary);
    box-shadow: var(--box-shadow-down);
    scale: 1.05;
    overflow: hidden;
}

/* Pending Projects */
.right .projects-updates {
    margin-top: 1rem;
}

.right .projects-updates h1 {
    margin-bottom: 0.8rem;
}

.right .projects-updates .updates {
    background: var(--color-white);
    padding: var(--card-padding);
    border-radius: var(--card-border-radius);
    box-shadow: var(--box-shadow);
    transition: all 0.3s ease;
}

.right .projects-updates .updates:hover {
    box-shadow: var(--box-shadow-down);
}

.right .projects-updates .updates .update {
    display: grid;
    grid-template-columns: 2.6rem auto;
    gap: 1rem;
    margin-bottom: 1rem;
    cursor: pointer;
    transition: all 0.3s ease;
    padding: 10px;
}

.right .projects-updates .updates .update:hover {
    border-radius: 10px;
    overflow: hidden;
    transform: scale(1.1);
    box-shadow: var(--box-shadow);
}

.message p {
    display: flex;
    align-items: center;
    justify-content: space-between;
}


/* Projects Routes CSS */
.search {
    width: 100%;
    margin: 1.2rem 0;
}

.search input {
    width: 100%;
    padding: 0.9rem 1rem;
    outline: none;
    border: none;
    color: var(--color-primary);
    background: var(--color-white);
    box-shadow: var(--box-shadow);
    border-radius: var(--card-border-radius);
}

.search input:hover {
    box-shadow: var(--box-shadow-down);
}
.add-btn{
display: flex;
align-items: center;
justify-content: center;
margin-top: 2rem;
}
.add-btn button{
    padding: 10px;
    width: 50%;
    border-radius: 30px;
    border: none;
    font-weight: 600;
    cursor: pointer;
    color: var(--color-primary);
    background: var(--color-white);
    box-shadow: var(--box-shadow);
    border-radius: var(--card-border-radius);
    transition: all 0.3s ease;
}
.add-btn button:hover{
    scale: 1.1;
}
.add-btn button:active{
    scale: 0.8;
    box-shadow: var(--box-shadow-down);
}


/* Project Details Data */
.project-details{
    display: grid;
    justify-content: center;
    align-items: center;
    grid-template-columns: repeat(1,1fr);
}
.project-details .pro-img>img{
    border-radius: 9px;
}
.project-details .pro-img{
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
    border: 0.5rem solid #75a1b3;
    box-shadow: var(--box-shadow);
    width: 100%;
    margin: 1rem 0;
}
.project-details .project-info{
    padding: 1rem;
}


/* Profile Route */

.profile-img{
    display: grid;
    align-items: center;
    justify-content: center;
    margin: 0  0 3rem 0;
}
.profile-img>div{
    height: 90px;
    width: 90px;
    border-radius: 50%;
    margin: 10px auto;
}
.profile-info{
    display: flex;
    align-items: center;
    justify-content: center;
}
.profile-info>h3{
    font-size: 1.1rem;
    color: var(--color-primary);
}
.profile-info>h3>span{
    color: var(--color-info-dark);
}
.about{
    margin: 1rem 5rem;
}
.about h3{
    color: var(--color-primary);
    font-size: 1.2rem;
}

.about>div{
    display: flex;
    align-items: center;
    justify-content: center;
}
.about>div>button{
    margin-top: 3rem;
    width: 300px;
    font-size: 1.3rem;
    font-weight: 500;
    color: var(--color-primary-varient);
    border: none;
    border-radius: 15px;
    padding: 12px 20px;
    background: var(--color-white);
    box-shadow: var(--box-shadow);
    transition: all 0.3s ease;
}
.about>div>button:hover{
    scale: 1.1;
    box-shadow: var(--box-shadow-down);
    cursor: pointer;
}
form input{
    width: 100%;
    background: var(--color-white);
    height: 40px;
    padding: 10px;
    border: none;
    border-radius: 12px;
    outline: none;
    font-size: 1em;
    box-shadow: var(--box-shadow);
    margin-top: 10px;
}
.about-input{
    height: 70px;
}
.inputs span{
    margin: 0  0 10px 12px;
    color: var(--color-primary);
}
.profile-form{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    gap: 2rem;
}
.update-btn{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
}
.update-btn>button{
    padding: 15px;
    font-size: 1.3rem;
    border-radius: 15px;
    outline: none;
    border: none;
    background: var(--color-white);
    font-weight: 500;
    box-shadow: var(--box-shadow);
    color: var(--color-primary-varient);
    transition: all 0.3s ease;
}
.update-btn>button:hover{
scale: 1.1;
box-shadow: var(--box-shadow-down);
cursor: pointer;
}


/* Login Page */
.login-container{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
}
.drop{
    position: relative;
    width: 350px;
    height: 350px;
    box-shadow: inset 20px 20px 20px rgba(0,0,0,0.05),
    25px 35px 20px rgba(0,0,0,0.05),
    25px 30px 30px rgba(0,0,0,0.05),
    inset -20px -20px 25px rgba(255,255,255,0.9);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 52% 48% 33% 67% / 38% 45% 55% 62% ;
}
.login-container .drop:hover{
    border-radius: 50% ;
}
.login-container .drop::before{
    content: '';
    position: absolute;
    top: 50px;
    left: 85px;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background: #fff;
    opacity: 0.8;
}
.login-container .drop::after{
    content: '';
    position: absolute;
    top: 90px;
    left: 110px;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: #fff;
    opacity: 0.8;
}
.login-container .drop .content{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    padding: 40px;
    gap: 15px;
}
.login-container .drop .content h1{
    position: relative;
    color: var(--color-dark);
    font-size: 1.5em;
}
.login-container .drop .content form{
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    align-items: center;
}
.login-container .drop .content form .inputBox{
    position: relative;
    width: 225px;
    box-shadow: inset 2px 5px 10px rgba(0,0,0,0.1),
    inset -2px -5px 15px rgba(255,255,255,1),
    15px 15px 10px rgba(0,0,0,0.05),
    15px 10px 15px rgba(0,0,0,0.025);
    border-radius: 25px;
}
.login-container .drop .content form .inputBox::before{
    content: '';
    position: absolute;
    top: 8px;
    left: 20%;
    transform: translateY(-20%);
    width: 55%;
    height: 3px;
    background: rgba(255,255,255,0.5);
    border-radius: 5px;

}
.login-container .drop .content form .inputBox input{
    border: none;
    outline: none;
    background: transparent;
    width: 100%;
    margin: 0 !important;
    padding: 13px 15px;
    height: auto;
    font-size: 1em;
    border-radius: 25px;
}
.login-container .drop .content form .inputBox input[type="submit"]{
    cursor: pointer;
    color: #fff;
    font-weight: bold;
    font-size: 1.3em !important;
    letter-spacing: 0.1em;
}
.login-container .drop .content form .inputBox:last-child{
    width: 120px;
    background: #ff0f5b;
    box-shadow: inset 2px 5px 10px rgba(0,0,0,0.1),
    15px 15px 10px rgba(0,0,0,0.05),
    15px 10px 15px rgba(0,0,0,0.025);
    transition: all 0.3s ease;
}
.login-container .drop .content form .inputBox:last-child:hover{
    width: 150px;
}





/* Responsive Media Queries */
/* Tab */
@media screen and (max-width: 1200px) {
    .container {
        width: 94%;
        grid-template-columns: 5rem auto 23rem;
    }

    aside .logo h2 {
        display: none;
    }

    aside .sidebar h3 {
        display: none;
    }

    aside .sidebar a {
        width: 5.6rem;
    }

    aside .sidebar a:last-child {
        position: relative;
        margin-top: 1.8rem;
    }

    main .insights {
        grid-template-columns: repeat(1, 1fr);
    }

    main .recent-project {
        width: 94%;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        margin: 2rem 0 0 8.8rem;
    }

    main .recent-project table {
        width: 83vw;
    }

    main .project table thead tr th:nth-child(3),
    main .project table thead tr th:last-child,
    main .project table thead tr th:first-child {
        display: none;
    }

    main .project table tbody tr td:last-child,
    main .project table tbody tr td:nth-child(3),
    main .project table tbody tr td:first-child {
        display: none;
    }
    main .project table .project-th tr th:nth-child(5) {
        display: none;
    }

    main .project table .project-tb tr td:nth-child(5),
    main .project table .project-tb tr td:nth-child(6){
        display: none;
    }
    .profile-form{
        grid-template-columns: repeat(1,1fr);
    }
}



/* Mobile  */

@media screen and (max-width: 768px) {
    .container {
        width: 100%;
        grid-template-columns: 1fr;
    }

    aside {
        display: none;
        position: fixed;
        left: 0;
        background: var(--color-white);
        width: 17rem;
        z-index: 3;
        border-radius: 10px;
        box-shadow: var(--box-shadow);
        height: 100vh;
        padding-right: var(--card-padding);
        transition: all 0.3s ease;
    }

    aside .logo {
        margin-left: 1rem;
    }

    aside .logo h2 {
        display: inline;
    }

    aside .sidebar h3 {
        display: inline;
        font-size: 1.15rem;
    }

    aside .sidebar a {
        width: 100%;
        height: 3.4rem;
        margin: 10px;
    }

    aside .sidebar a:last-child {
        position: absolute;
        bottom: 5rem;
    }

    aside .close {
        display: inline-block;
        cursor: pointer;
    }

    main {
        margin-top: 8rem;
        padding: 0 1rem;
    }

    main .recent-project {
        position: relative;
        margin: 3rem 0 0 0;
        width: 100%;
    }

    main .recent-project table {
        width: 100%;
        margin: 0;
    }

    .right {
        width: 94%;
        margin: 0 auto 4rem;
    }

    .right .top {
        position: fixed;
        top: 0;
        left: 0;
        align-items: center;
        padding: 0 0.8rem;
        height: 5rem;
        background: var(--color-white);
        width: 100%;
        margin: 0;
        z-index: 2;
        box-shadow: 0 1rem 1rem var(--color-light);
    }

    .right .theme-toggle {
        width: 4.2rem;
        position: absolute;
        left: 66%;
    }

    .right .profile .info {
        display: none;
    }

    .right .top button {
        display: inline-block;
        border: none;
        background: transparent;
        cursor: pointer;
        color: var(--color-dark);
        position: absolute;
        left: 1rem;
    }

    .right .top button i {
        font-size: 2rem;
    }
}